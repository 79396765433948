import { AgGridAngular } from '@ag-grid-community/angular';
import { type GridApi, type GridOptions } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Injector,
  Input,
  type OnChanges,
  type OnInit,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { type FleetEntity } from '@sds/api/administration/models';
import { type PlantOverview } from '@sds/api/error-detection/models';
import { type KeyPerformanceIndicators } from '@sds/api/visualisation/models';
import { HealthStatusRendererComponent } from '@sds/health-status';
import { ConfigProperty, ConfigService, GridOptionsBase, SharedModule } from '@sds/shared';
import { HealthStatusHeaderRendererComponent } from '@sds/shared/components';
import { AgGridCellClass } from '@sds/shared/enums';
import { apiDateFormatter } from '@sds/shared/utils';
import { WidgetHeaderButtonsComponent } from '@sds/widget-header-buttons';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import { first } from 'rxjs/operators';
import { KpiDatasource } from './kpi.datasource';
import { KpiService } from './kpi.service';
import { KPIHeaderRendererComponent } from './renderers/kpi-header-renderer';
import { PerformanceRatioCellRendererComponent } from './renderers/performance-ratio-cell.renderer';
@Component({
  standalone: true,
  imports: [SharedModule, WidgetHeaderButtonsComponent, AgGridAngular],
  selector: 'sds-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiComponent implements OnChanges, OnInit {
  @Input()
  selectedFleet: FleetEntity | undefined = undefined;
  private configService = inject(ConfigService);
  private translateService = inject(TranslateService);
  private apiService = inject(KpiService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private destroyRef = inject(DestroyRef);
  private injector = inject(Injector);
  gridOptions: GridOptions;
  gridModules = GridOptionsBase.modules;
  defaultPageSize: number = this.configService.get(ConfigProperty.DefaultPageSize);
  cacheBlockSize: number = this.configService.get(ConfigProperty.DefaultPageSize);
  csvExportPageSize = 1048576;
  private datasource = new KpiDatasource(this.apiService);
  hasHeatmapPermission = false;
  gridApi?: GridApi;
  ngOnInit() {
    this.gridOptions = GridOptionsBase.init(
      {
        columnDefs: [
          {
            headerName: '',
            children: [
              {
                colId: 'healthStatus',
                headerComponent: HealthStatusHeaderRendererComponent,
                cellRenderer: HealthStatusRendererComponent,
                width: 20,
                suppressAutoSize: true,
                suppressHeaderFilterButton: true,
                sortable: false,
                valueGetter: params => {
                  return params.data?.healthStatus;
                },
                headerValueGetter: () => this.translateService.instant('kpi.headers.healthStatus')
              },
              {
                headerName: this.translateService.instant('shared.plant'),
                field: 'label'
              },
              {
                headerName: this.translateService.instant('shared.identifier'),
                field: 'identifier'
              },
              {
                headerName: this.translateService.instant('shared.plantSize') + ' (kWp)',
                headerClass: AgGridCellClass.HeaderAlignRight,
                field: 'size',
                cellClass: AgGridCellClass.CellAlignRight + ' last-group-cell',
                valueGetter: params => {
                  if (params.data) {
                    const plantErrorOverview = params.data as PlantOverview;
                    return plantErrorOverview.size !== null && plantErrorOverview.size !== undefined
                      ? (plantErrorOverview.size / 1000).toFixed(2)
                      : '';
                  } else {
                    return '';
                  }
                }
              }
            ]
          },
          {
            headerValueGetter: () => this.translateService.instant('shared.techAv') + ' (%)',
            headerGroupComponent: KPIHeaderRendererComponent,
            children: [
              {
                headerName: this.translateService.instant('shared.time.today'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'techAvToday',
                cellClass: params => {
                  return params.value ? AgGridCellClass.CellAlignRight + ' clickable' : AgGridCellClass.CellAlignRight;
                },
                valueGetter: val => {
                  return val.data?.techAvToday !== null && val.data?.techAvToday !== undefined
                    ? this.roundPercentage(val.data.techAvToday)
                    : '';
                }
              },
              {
                headerName: this.translateService.instant('shared.time.thisMonth'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'techAvMonth',
                cellClass: AgGridCellClass.CellAlignRight,
                valueGetter: val => {
                  return val.data?.techAvMonth !== null && val.data?.techAvMonth !== undefined
                    ? this.roundPercentage(val.data.techAvMonth)
                    : '';
                }
              },
              {
                headerName: this.translateService.instant('shared.time.thisYear'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'techAvYear',
                cellClass: AgGridCellClass.CellAlignRight + ' last-group-cell',
                valueGetter: val => {
                  return val.data?.techAvYear !== null && val.data?.techAvYear !== undefined
                    ? this.roundPercentage(val.data.techAvYear)
                    : '';
                }
              }
            ]
          },
          {
            headerName: this.translateService.instant('kpi.headers.prodEtotalNorm') + ' (kwh/kwp)',
            headerGroupComponent: KPIHeaderRendererComponent,
            children: [
              {
                headerName: this.translateService.instant('shared.time.today'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'prodEtotalNormToday',
                cellClass: params => {
                  return params.value ? AgGridCellClass.CellAlignRight + ' clickable' : AgGridCellClass.CellAlignRight;
                },
                valueGetter: val => {
                  return val.data?.prodEtotalNormToday !== null && val.data?.prodEtotalNormToday !== undefined
                    ? val.data.prodEtotalNormToday.toFixed(2)
                    : '';
                }
              },
              {
                headerName: this.translateService.instant('shared.time.thisMonth'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'prodEtotalNormMonth',
                cellClass: params => {
                  return params.value ? AgGridCellClass.CellAlignRight + ' clickable' : AgGridCellClass.CellAlignRight;
                },
                valueGetter: val => {
                  return val.data?.prodEtotalNormMonth !== null && val.data?.prodEtotalNormMonth !== undefined
                    ? val.data.prodEtotalNormMonth.toFixed(2)
                    : '';
                }
              },
              {
                headerName: this.translateService.instant('shared.time.thisYear'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'prodEtotalNormYear',
                cellClass: params => {
                  return params.value
                    ? AgGridCellClass.CellAlignRight + ' clickable last-group-cell'
                    : AgGridCellClass.CellAlignRight + ' last-group-cell';
                },
                valueGetter: val => {
                  return val.data?.prodEtotalNormYear !== null && val.data?.prodEtotalNormYear !== undefined
                    ? val.data.prodEtotalNormYear.toFixed(2)
                    : '';
                }
              }
            ]
          },
          {
            headerName: this.translateService.instant('kpi.headers.performanceRatio') + ' (%)',
            headerGroupComponent: KPIHeaderRendererComponent,
            children: [
              {
                headerName: this.translateService.instant('shared.time.today'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'performanceRatioWeatherToday',
                cellClass: params => {
                  return params.value ? AgGridCellClass.CellAlignRight + ' clickable' : AgGridCellClass.CellAlignRight;
                },
                valueGetter: val => {
                  return val.data?.performanceRatioWeatherToday !== null &&
                    val.data?.performanceRatioWeatherToday !== undefined
                    ? val.data.performanceRatioWeatherToday.toFixed(2)
                    : '';
                },
                cellRenderer: PerformanceRatioCellRendererComponent
              },
              {
                headerName: this.translateService.instant('shared.time.thisMonth'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'performanceRatioWeatherMonth',
                cellClass: params => {
                  return params.value ? AgGridCellClass.CellAlignRight + ' clickable' : AgGridCellClass.CellAlignRight;
                },
                valueGetter: val => {
                  return val.data?.performanceRatioWeatherMonth !== null &&
                    val.data?.performanceRatioWeatherMonth !== undefined
                    ? val.data.performanceRatioWeatherMonth.toFixed(2)
                    : '';
                },
                cellRenderer: PerformanceRatioCellRendererComponent
              },
              {
                headerName: this.translateService.instant('shared.time.thisYear'),
                headerClass: AgGridCellClass.HeaderAlignCenter,
                field: 'performanceRatioWeatherYear',
                cellClass: params => {
                  return params.value
                    ? AgGridCellClass.CellAlignRight + ' clickable last-group-cell'
                    : AgGridCellClass.CellAlignRight + ' last-group-cell';
                },
                valueGetter: val => {
                  return val.data?.performanceRatioWeatherYear !== null &&
                    val.data?.performanceRatioWeatherYear !== undefined
                    ? val.data.performanceRatioWeatherYear.toFixed(2)
                    : '';
                },
                cellRenderer: PerformanceRatioCellRendererComponent
              }
            ]
          }
        ],
        suppressCellFocus: true,
        suppressHorizontalScroll: false,
        enableBrowserTooltips: true,
        suppressColumnVirtualisation: true,
        context: {
          componentParent: this
        },
        noRowsOverlayComponentParams: {
          noRowsMessage: 'kpi.noData',
          noRowsIcon: 'insights',
          noRowsAction: this.createPlant,
          noRowsActionMessage: 'kpi.actionMessage'
        },
        defaultColDef: {
          sortable: false,
          suppressHeaderFilterButton: true,
          suppressAutoSize: false,
          suppressSizeToFit: false
        },
        rowModelType: 'infinite',
        paginationPageSize: this.defaultPageSize,
        cacheBlockSize: this.defaultPageSize,
        datasource: this.datasource,
        onFirstDataRendered: event => {
          event.api.autoSizeAllColumns();
          event.api.hideOverlay();
        },
        onCellClicked: params => {
          if (params.data && params.value) {
            this.navigate(params.column.getColId(), params.data);
          }
        },
        onGridSizeChanged: event => {
          event.api.sizeColumnsToFit();
        },
        onGridReady: event => {
          this.gridApi = event.api;
        }
      },
      null,
      this.injector
    );
  }
  navigate(colId: string, kpi: KeyPerformanceIndicators) {
    const plantId = kpi.plant?.replace('/plant/', '');
    const todayDate = new Date();
    const today = apiDateFormatter(todayDate);
    const monthStart = apiDateFormatter(startOfMonth(todayDate));
    const monthEnd = apiDateFormatter(endOfMonth(todayDate));
    const yearStart = apiDateFormatter(startOfYear(todayDate));
    const yearEnd = apiDateFormatter(endOfYear(todayDate));
    switch (colId) {
      case 'prodEtotalNormToday': {
        window.open(`/analysis/detail/${plantId}?startDate=${today}&endDate=${today}`, '_blank');
        break;
      }
      case 'prodEtotalNormMonth': {
        window.open(`/analysis/detail/${plantId}?startDate=${monthStart}&endDate=${monthEnd}`, '_blank');
        break;
      }
      case 'prodEtotalNormYear': {
        window.open(`/analysis/detail/${plantId}?startDate=${yearStart}&endDate=${yearEnd}`, '_blank');
        break;
      }
      case 'techAvToday':
        this.apiService
          .getComponentsWithTechAvByPlant(plantId!, todayDate)
          .pipe(first(), takeUntilDestroyed(this.destroyRef))
          .subscribe(componentIds => {
            let componentsString = '';
            componentIds.forEach(componentId => {
              componentsString += `components=${componentId}&`;
            });
            window.open(
              `/analysis/detail/${plantId}?startDate=${today}&endDate=${today}&chart=customChart&${componentsString}channel1=ProdPacNorm&channel2=TechAv`,
              '_blank'
            );
          });
        break;
      case 'performanceRatioWeatherToday': {
        window.open(
          `/analysis/detail/${plantId}?startDate=${today}&endDate=${today}&channelNames=InverterPerformanceRatioWeather&chartType=line&chart=performanceRatioHeatmap`,
          '_blank'
        );
        break;
      }
      case 'performanceRatioWeatherMonth': {
        window.open(
          `/analysis/detail/${plantId}?startDate=${monthStart}&endDate=${monthEnd}&channelNames=InverterPerformanceRatioWeather&chartType=line&chart=performanceRatioHeatmap`,
          '_blank'
        );
        break;
      }
      case 'performanceRatioWeatherYear': {
        window.open(
          `/analysis/detail/${plantId}?startDate=${yearStart}&endDate=${yearEnd}&channelNames=InverterPerformanceRatioWeather&chartType=line&chart=performanceRatioHeatmap`,
          '_blank'
        );
        break;
      }
    }
  }
  loadData() {
    this.gridApi?.purgeInfiniteCache();
  }
  createPlant = () => {
    this.router.navigate(['/administration/plants'], {
      queryParams: { createPlant: true },
      relativeTo: this.route
    });
  };
  csvExport() {
    this.cacheBlockSize = this.csvExportPageSize;
    this.gridApi?.setGridOption('paginationPageSize', this.csvExportPageSize);
    this.gridApi?.setGridOption('datasource', this.datasource);
  }
  ngOnChanges() {
    this.loadData();
  }
  roundPercentage(val: number) {
    if (val === 100) {
      return val;
    }
    return val.toFixed(2);
  }
}
